import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const LuminDarkIcon = createIcon({
  displayName: 'LuminDarkIcon',
  viewBox: '0 0 601.3 100',
  path: (
    <g>
      <path d="M282.7,49.6c0-10.3,0-20.6,0-30.9c0-1.6,0.2-1.8,1.8-1.8c1.1,0,2.2,0,3.4,0c0.8,0,1.4,0.3,1.8,1
        c2,3.1,4.1,6.2,6.1,9.2c2.3,3.5,4.5,7,6.7,10.4c1.2,1.9,2.5,3.7,3.7,5.6c2.3,3.5,4.6,7,6.9,10.5c0.5,0.8,0.8,0.8,1.4,0
        c0.8-1.2,1.6-2.4,2.4-3.6c1.4-2.1,2.8-4.3,4.2-6.4c1.1-1.7,2.1-3.4,3.2-5c1.3-2,2.6-4,3.9-6c1.7-2.7,3.4-5.3,5.2-8
        c1.5-2.3,3-4.7,4.6-6.9c0.3-0.4,0.9-0.7,1.4-0.7c1.4-0.1,2.8-0.1,4.2,0c0.9,0,1.2,0.3,1.2,1.3c0,0.6,0,1.3,0,1.9
        c0,19.8,0,39.6,0,59.4c0,0.5,0,1.1,0,1.6c0,0.7-0.3,1.1-1,1.2c-1.2,0-2.3,0-3.5,0c-0.6,0-0.8-0.4-0.8-0.9c0-0.6,0-1.2,0-1.8
        c0-16.9,0-33.8,0-50.7c0-0.8,0-1.6,0-2.5c0-0.2-0.2-0.5-0.4-0.7c-0.2,0.1-0.5,0.3-0.6,0.4c-1.7,2.7-3.5,5.3-5.2,8
        c-2.7,4.1-5.3,8.2-8,12.3c-2.5,3.8-4.9,7.5-7.4,11.3c-0.8,1.2-1.5,2.4-2.3,3.6c-0.5,0.7-1,1.5-2.1,1.3c-0.5-0.1-1-0.4-1.2-0.8
        c-2-3-4-6-6-9c-2.3-3.5-4.6-7.1-6.9-10.6c-1.6-2.5-3.3-4.9-4.9-7.3c-1.6-2.5-3.3-5-4.9-7.5c-0.3-0.4-0.6-0.8-0.9-1.1
        c-0.4-0.4-0.7-0.2-0.8,0.3c0,0.6,0,1.2,0,1.8c0,17,0,34.1,0,51.1c0,0.6,0,1.2,0,1.8c0,0.7-0.3,1.1-1,1.1c-1.1,0-2.2,0-3.3,0
        c-0.9,0-1.2-0.4-1.2-1.2C282.6,70.5,282.6,60.1,282.7,49.6C282.6,49.6,282.7,49.6,282.7,49.6z"/>
      <path d="M527.7,49.7c0-10.3,0-20.7,0-31c0-0.6-0.2-1.4,0.6-1.7c0.8-0.3,1.6-0.1,2.3,0.6c1.4,1.6,2.7,3.3,4.1,5
        c2.9,3.6,5.9,7.2,8.8,10.8c2,2.4,3.9,4.9,5.9,7.3c2.2,2.7,4.4,5.4,6.6,8.1c1.6,2,3.2,4,4.9,6c1.9,2.4,3.9,4.8,5.8,7.2
        c1.9,2.4,3.8,4.8,5.7,7.1c0.2,0.3,0.6,0.5,1,0.7c0.1-0.4,0.4-0.8,0.4-1.2c0-8.5,0-17,0-25.4c0-8.2,0-16.4,0-24.6c0-0.3,0-0.6,0-0.9
        c0.1-0.5,0.4-0.8,0.9-0.8c1.1,0,2.1,0,3.2,0c0.7,0,1.1,0.4,1.1,1.1c0,0.5,0,1.1,0,1.6c0,20,0,40.1,0,60.1c0,0.3,0,0.6,0,0.9
        c0,0.6,0.1,1.2-0.6,1.5c-0.7,0.2-1.5,0.3-2.1-0.3c-0.9-1-1.7-1.9-2.5-2.9c-1.7-2.1-3.5-4.2-5.2-6.3c-3.2-3.9-6.4-7.9-9.5-11.8
        c-2.5-3.1-5.1-6.2-7.6-9.3c-2.9-3.5-5.7-7.1-8.6-10.6c-2.3-2.8-4.7-5.7-7-8.5c-0.7-0.8-1.3-1.6-1.9-2.4c-0.2-0.2-0.5-0.3-0.7-0.4
        c-0.1,0.3-0.3,0.5-0.4,0.8c0,0.7,0,1.5,0,2.2c0,16.1,0,32.3,0,48.4c0,1.2-0.2,1.4-1.4,1.4c-0.9,0-1.9,0-2.8,0
        c-0.8,0-1.1-0.4-1.1-1.3c0-10.1,0-20.2,0-30.3C527.7,50.4,527.7,50,527.7,49.7C527.7,49.7,527.7,49.7,527.7,49.7z"/>
      <path d="M194.6,38.5c0,6.6,0,13.1,0,19.7c0,2.9-0.4,5.7-1.3,8.4c-1.3,4.2-3.6,7.8-6.9,10.6c-2.1,1.8-4.4,3.1-7,4.2
        c-4.4,1.8-8.9,1.9-13.5,1.6c-2.7-0.2-5.2-1-7.5-2.1c-5-2.3-8.9-5.9-11.3-11c-1.5-3.1-2.5-6.4-2.6-9.9c-0.1-7.7-0.1-15.4-0.2-23.1
        c0-6.1,0-12.2,0-18.2c0-0.3,0-0.6,0-0.9c0.1-0.6,0.4-0.9,1-0.9c1,0,2,0,3,0c0.7,0,1.1,0.4,1.2,1.1c0,0.2,0,0.4,0,0.7
        c0,13.2-0.1,26.3,0,39.5c0,4.6,1.2,8.9,4.1,12.7c1.6,2.1,3.6,3.8,6,5.1c4.1,2.2,8.4,3,13,2.3c2.2-0.4,4.3-1.1,6.4-2.2
        c3.5-1.8,6-4.4,7.8-7.7c1.1-2,1.9-4.1,2.1-6.3c0.2-2.3,0.4-4.7,0.4-7c0-12.1,0-24.2,0-36.3c0-1.6,0.2-1.8,1.8-1.8c0.7,0,1.3,0,2,0
        c1.1,0,1.4,0.2,1.4,1.3c0,0.8,0,1.5,0,2.3C194.6,26.5,194.6,32.5,194.6,38.5C194.6,38.5,194.6,38.5,194.6,38.5z"/>
      <path d="M22.2,49.6c0-10.3,0-20.6,0-30.8c0-1.7,0.2-1.9,1.9-1.9c0.8,0,1.6,0,2.4,0c0.8,0,1.2,0.4,1.2,1.1
        c0,0.3,0,0.5,0,0.8c0,18.4,0,36.8,0,55.2c0,0.8,0,1.5,0,2.3c0,0.7,0.4,1,1.1,1.1c0.6,0,1.2,0,1.8,0c10.7,0,21.3,0,32,0
        c1.5,0,1.7,0.2,1.7,1.7c0,0.7,0,1.4,0,2c0,0.7-0.4,1.1-1,1.1c-0.2,0-0.5,0-0.7,0c-12.8,0-25.6,0-38.4,0c-0.2,0-0.5,0-0.7,0
        c-0.8,0-1.2-0.4-1.1-1.2c0-0.5,0-1.1,0-1.6C22.2,69.5,22.2,59.5,22.2,49.6C22.2,49.6,22.2,49.6,22.2,49.6z"/>
      <path d="M438.8,49.7c0,10.4,0,20.8,0,31.2c0,1.1-0.3,1.3-1.4,1.4c-0.8,0-1.7,0-2.5,0c-1,0-1.3-0.4-1.3-1.4
        c0-20.7,0-41.5,0-62.2c0-1.5,0.3-1.8,1.8-1.8c0.7,0,1.5,0,2.2,0c0.7,0,1.1,0.4,1.2,1.1c0,0.3,0,0.5,0,0.8
        C438.8,29.1,438.8,39.4,438.8,49.7C438.8,49.7,438.8,49.7,438.8,49.7z"/>
    </g>
  ),
})
