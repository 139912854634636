import { Box, Text } from '@chakra-ui/core'
import DOMPurify from 'dompurify'
import htmlParser from 'html-react-parser'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useInfluencer } from '../../../influencers/hooks'
import { useFeatureFlag } from '../../../optimizely/hooks/useFeatureFlag'
import { translatePromotionText } from '../../../promotions/functions'
import { usePromotion } from '../../../promotions/hooks'
import { AfterpayClearpayBar } from './AfterpayClearPayBar'
import FatherDaySaleBanner from './FatherDaySaleBanner'

import { useAvailableStoreLocationMessage } from 'modules/lumin/hooks/availableStoreLocation'
import { useShippingCountryMessage } from 'modules/lumin/hooks/shippingCountries'

const HeaderWrappeer: React.FC = ({ children }) => {
  return (
    <Box backgroundColor="charcoal" color="white" textAlign="center" p={2}>
      {children}
    </Box>
  )
}

const AnnouncementBar: React.FC<{
  text?: string
  setBannerShown?: (value: boolean) => void
}> = ({ text, setBannerShown }) => {
  let announcement = useFeatureFlag('ANNOUNCEMENT_TEXT', '') // this set from optimizely and usually contains html

  if (typeof announcement === 'function') {
    announcement = announcement()
  }

  if (text) {
    announcement = text
  }

  const showAfterpayBar = useFeatureFlag('ENABLE_AFTERPAY_BAR', false)
  const showFatherDaySaleBanner = useFeatureFlag(
    'SHOW_FATHER_DAY_BANNER',
    false
  )

  const shippingMessage = useShippingCountryMessage()
  const storeLocationMessage = useAvailableStoreLocationMessage()

  const promotion = usePromotion()
  const influencer = useInfluencer()
  const { t } = useTranslation()

  if (promotion && promotion.banner) {
    const promoStartDate = new Date(promotion.startDate)
    const promoEndDate = new Date(promotion.endDate)
    const currentDate = new Date()
    if (currentDate > promoStartDate && currentDate < promoEndDate) {
      announcement = translatePromotionText(t, promotion.banner, 1, 'USD')
    }
  }

  if (influencer) {
    announcement = influencer.banner
  }

  if (storeLocationMessage) {
    announcement = storeLocationMessage
  }

  if (shippingMessage) {
    announcement = shippingMessage
  }

  useEffect(() => {
    setBannerShown?.(
      showAfterpayBar || showFatherDaySaleBanner || !!announcement
    )
  }, [showAfterpayBar, showFatherDaySaleBanner, announcement])

  if (showAfterpayBar && !announcement) {
    return (
      <HeaderWrappeer>
        <AfterpayClearpayBar />
      </HeaderWrappeer>
    )
  }

  if (showFatherDaySaleBanner) {
    return (
      <HeaderWrappeer>
        <FatherDaySaleBanner />
      </HeaderWrappeer>
    )
  }

  if (!announcement) {
    return null
  }

  return (
    <HeaderWrappeer>
      <Text
        as="div"
        className="announcement-text"
        fontSize="sm"
        lineHeight="1.5em"
      >
        {htmlParser(DOMPurify.sanitize(announcement))}
      </Text>
    </HeaderWrappeer>
  )
}

export default AnnouncementBar
