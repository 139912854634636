import { useState, useEffect, useRef } from 'react'
import { noop } from '@pangaea-holdings/pangaea-checkout'

export const useTimeLeft = (
  duration: number,
  onEnd = noop,
  loop = false
) => {
  const [timeLeft, setTimeLeft] = useState(duration)
  const callbackRef = useRef(onEnd)

  useEffect(() => {
    if (timeLeft <= 0 && !loop) {
      return
    }
    const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000)
    return () => {
      clearTimeout(timerId)
    }
  }, [timeLeft])

  useEffect(() => {
    callbackRef.current = onEnd
  }, [onEnd])

  useEffect(() => {
    if (timeLeft === 0) {
      if (loop) {
        setTimeLeft(duration)
      }
      callbackRef.current()
    }
    // We can leave out callbackRef from the dependency array because it's
    // immutable but if the linter complains, it's safe to add it.
  }, [timeLeft])

  return timeLeft
}
