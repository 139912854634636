import * as Sentry from '@sentry/browser'
import {
  Promotion,
  storefrontApiJsonFetch,
  CheckoutContext,
  applyPromotionToCart,
  selectCartId,
  removeCookie,
  getCookie,
} from '@pangaea-holdings/pangaea-checkout'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'

import { getActivePromoCode } from './functions'

type PromotionCache = {
  [k: string]: Promotion
}

const loadPromotion = (() => {
  const cachedPromotion: PromotionCache = {}

  return async (promoCode) => {
    if (!cachedPromotion[promoCode]) {
      const res = await storefrontApiJsonFetch(`/api/promotions/${promoCode}`)

      if (res.isError) {
        throw new Error(`Error getting promotion: ${promoCode}. ${res.message}`)
      }

      cachedPromotion[promoCode] = res.result as Promotion
    }
    return cachedPromotion[promoCode]
  }
})()

export const usePromotion = () => {
  const router = useRouter()
  const [promotion, setPromotion] = useState<Promotion | null>(null)
  const { state, dispatch } = useContext(CheckoutContext)
  const cartId = state ? selectCartId(state) : null

  useEffect(() => {
    const process = async () => {
      const promoCode = getActivePromoCode(router) || null

      if (promoCode) {
        loadPromotion(promoCode)
          .then((loadedPromotion) => {
            setPromotion(loadedPromotion)

            if (cartId) {
              // TODO: invoke only if the promotion is not already applied to the cart
              dispatch(applyPromotionToCart(promoCode))
            }
          })
          .catch((error) => {
            Sentry.captureException(error)
          })
      }
    }

    process()
  }, [router.query, cartId])

  return promotion
}

export const useCheckExpiredPromotionRedirect = () => {
  const [redirectedFromExpiredPromotion, setRedirectedFromExpiredPromotion] =
    useState(false)

  const cookieKey = 'promotion-expired-redirect'

  const resetPromotionRedirectState = () =>
    setRedirectedFromExpiredPromotion(false)

  useEffect(() => {
    const redirectCookie = getCookie(cookieKey)
    if (redirectCookie && redirectCookie === 'true') {
      setRedirectedFromExpiredPromotion(true)
      removeCookie(cookieKey)
    }
  }, [])
  return { redirectedFromExpiredPromotion, resetPromotionRedirectState }
}
