import { Product } from '@pangaea-holdings/pangaea-checkout'

import { AppState } from '../../redux/rootReducer'

export const selectAllProducts = (currency: string) => (state: AppState) => {
  return state.products.all[currency] as Product[] | undefined
}

export const selectProductsSubDiscountKeys = () => (state: AppState) =>
  state.products.subDiscountKeys

type FindSingleArgs =
  | { id: number; currency: string }
  | { slug: string; currency: string }

/**
 * Returns:
 * null if the products are loaded but can find by slug or id
 * undefined if the products are NOT loaded for that currency
 * Product if found
 */
export const selectSingleProduct =
  (opts: FindSingleArgs) => (state: AppState) => {
    const productsArr = state.products.all[opts.currency] as
      | Product[]
      | undefined

    if (productsArr) {
      if ('slug' in opts) {
        return productsArr.find((a) => a.slug === opts.slug) ?? null
      }
      if ('id' in opts) {
        return productsArr.find((a) => a.id === opts.id) ?? null
      }
    }

    const singleArr = state.products.single[opts.currency]
    if (singleArr) {
      if ('slug' in opts) {
        return singleArr.find((a) => a.slug === opts.slug) ?? undefined
      }
      if ('id' in opts) {
        return singleArr.find((a) => a.id === opts.id) ?? undefined
      }
    }

    return undefined
  }

export const selectMultipleProducts =
  (currency: string, ids: number[]) => (state: AppState) => {
    const products = ids
      .map((id) => selectSingleProduct({ currency, id })(state))
      .filter((a) => a)

    if (products.length === ids.length) {
      return products as Product[]
    }
    return undefined
  }

export const selectAllOutStock = (currency: string) => (state: AppState) => {
  return selectAllProducts(currency)(state)?.filter((p) => p.outOfStock)
}

export const selectCatalogProducts =
  (countryAndCurreny: string) => (state: AppState) => {
    return state.products.catalogs[countryAndCurreny] as Product[] | undefined
  }
