import { getUserCountryCode } from '@pangaea-holdings/pangaea-checkout'
import * as Sentry from '@sentry/browser'
import { useCallback, useEffect, useState } from 'react'

export function useCurrentCountryCode() {
  const [currentCountryCode, setCurrentCountryCode] =
    useState<string | null>(null)

  const fetchCountryCode = useCallback(async () => {
    try {
      const countryCode = await getUserCountryCode()
      setCurrentCountryCode(countryCode)
    } catch (e) {
      Sentry.captureException('Failed to fetch countryCode ', e)
    }
  }, [])

  useEffect(() => {
    fetchCountryCode()
  }, [])

  return currentCountryCode
}
