import {
  CheckoutContext,
  loadOrCreateCart,
  setProductGetter,
  updateCart,
} from '@pangaea-holdings/pangaea-checkout'
import { useEffect, useContext, useRef } from 'react'

import { useVatConfig } from '../../vat/hooks'

import { useFeatureFlag } from 'modules/optimizely/hooks/useFeatureFlag'
import { fetchProductCached } from 'modules/products/actions'
import { ProductRenewalDiscountRule } from 'modules/products/types'
import { useTypedDispatch } from 'redux/store'

function useInitializeCart() {
  const { state, dispatch } = useContext(CheckoutContext)
  const selectedCurrency = state.cart.selectedCurrency
  const cartId = state.cart.cart?.id
  const shouldUpdateCartWithCurrency = useRef(false)
  const { enabled: isVatCalcEnabled } = useVatConfig()
  const reduxDispatch = useTypedDispatch()

  const renewalDiscountRule: ProductRenewalDiscountRule = useFeatureFlag(
    'RENEWAL_DISCOUNT_RULE'
  )

  setProductGetter((id) => {
    return reduxDispatch(fetchProductCached(id, selectedCurrency))
  })

  useEffect(() => {
    dispatch(
      loadOrCreateCart(
        {
          currency: selectedCurrency,
          subscriptionDiscountPercentOff: renewalDiscountRule?.percentageOff,
          subscriptionDiscountKey: renewalDiscountRule?.subscriptionDiscountKey || '2month-2000',
        },
        isVatCalcEnabled
      )
    )
    shouldUpdateCartWithCurrency.current = false
  }, [renewalDiscountRule])

  useEffect(() => {
    if (cartId && shouldUpdateCartWithCurrency.current) {
      dispatch(
        updateCart(
          {
            currency: selectedCurrency,
            subscriptionDiscountPercentOff: renewalDiscountRule?.percentageOff,
            subscriptionDiscountKey:
              renewalDiscountRule?.subscriptionDiscountKey || '2month-2000',
          },
          isVatCalcEnabled
        )
      )
    }
    shouldUpdateCartWithCurrency.current = true
  }, [selectedCurrency, renewalDiscountRule, isVatCalcEnabled])
}

export default useInitializeCart
