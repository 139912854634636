import { Button, Text, Flex } from '@chakra-ui/core'
import { noop } from '@pangaea-holdings/pangaea-checkout'
import { TFunction } from 'i18next'
import Link from 'next/link'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import staticRoutes from '../../static/routes'
import { useTimerCountDown } from '../hooks/useTimerCountDown'

export interface NavTimerProps {
  getMessage: (t: TFunction) => string //pass it translated
  endsInMins?: number
  link?: string
  onEnd?: () => void
  loop?: boolean
  isPortal?: boolean
}

const NavTimer: FC<NavTimerProps> = ({
  getMessage,
  endsInMins = 20,
  link = staticRoutes.freeTrial(),
  onEnd = noop,
  loop = false,
  isPortal = false,
}) => {
  const { t } = useTranslation()
  const handleTimeCompletion = onEnd
  const { minutes, seconds } = useTimerCountDown(
    endsInMins,
    handleTimeCompletion,
    loop
  )

  const Banner = (
    <Link href={link}>
      <Button
        p={[4, 5]}
        width="100%"
        textAlign="center"
        variant="outline"
        border={0}
        backgroundColor="disabled"
      >
        <Flex
          as="h3"
          direction={['column', 'row', 'row']}
          justify="center"
          align="center"
        >
          <Text
            whiteSpace="normal"
            size="md"
            textTransform="uppercase"
            fontWeight="bold"
          >
            {getMessage(t)}
          </Text>
          <Text
            size="md"
            textTransform="uppercase"
            fontWeight="bold"
            ml={[0, 2, 2]}
          >
            {t(
              'listiquiz:offer-ends-in',
              'offer ends in: {{minutes}}:{{seconds}}',
              { minutes, seconds }
            )}
          </Text>
        </Flex>
      </Button>
    </Link>
  )

  return isPortal ? (
    Banner
  ) : (
    <Flex order={-1} position="sticky" top={0} zIndex="banner">
      {Banner}
    </Flex>
  )
}

export default NavTimer
