import { ProductInfo } from '@pangaea-holdings/pangaea-account'
import {
  Product,
  SizeOption,
  CustomizationOption,
  CountryResponse,
  getFirstSizeOptionId,
  SelectedOptions,
} from '@pangaea-holdings/pangaea-checkout'
import { TFunction } from 'i18next'

import { AppState } from '../../redux/rootReducer'
import { slugify } from '../../utils/string'
import { PRODUCTS_ORDER_BY_CATEGORY } from './constants/sortByOptions'
import { selectSingleProduct } from './selectors'
import type {
  ProductDetails,
  ProductCustomization,
  ProductWithDetails,
  ProductsByCategory,
  PriceRangeOption,
  SizeOptionsWithAvailabilty,
  CustomizationOptionsWithAvailabilty,
} from './types'
import { SizeStrategy } from './types'

/**
 * Checks to see if there are any options to be customized
 */
export function canCustomizeProduct(product: Product): boolean {
  const sizes = Object.values(product.sizes.options).filter((a) => a.isVisible)
  const customizationOptions = Object.values(
    product.customizationOptions
  ).filter((opt) => {
    return Object.values(opt.options).length > 1
  })

  return sizes.length === 1 && customizationOptions.length === 0
}

export function getDefaultCustomization(
  product: Product,
  skinType?: string
): ProductCustomization | null {
  if (!canCustomizeProduct(product) && !skinType) {
    return null
  }

  const sizes = Object.values(product.sizes.options).filter(
    (a) => a.isVisible || (product as ProductWithDetails).isOneMonthSupply
  )
  const customizationOptions = Object.values(
    product.customizationOptions
  ).reduce<number[]>((carry, opt) => {
    const options = Object.values(opt.options)
    const option = skinType
      ? options.find((o) => o.text.toLowerCase() === skinType.toLowerCase()) ||
        options[0]
      : options[0]
    carry.push(option.id)
    return carry
  }, [])

  return {
    productId: product.id,
    productSizeOptionId: sizes[0].id,
    productCustomizationOptionIds: customizationOptions,
  }
}

export function getCheapestVisibleSize(product: Product): SizeOption | null {
  const productWithHigherPrice = [43]
  const opts = Object.values(product.sizes.options)
    .filter(
      (a) => a.isVisible || (product as ProductWithDetails).isOneMonthSupply
    )

    .sort((a, b) => {
      return productWithHigherPrice.includes(product.id)
        ? b.price - a.price
        : a.price - b.price
    })

  return opts[0] ? opts[0] : null
}

export function getHighestVisibleSize(product: Product): SizeOption | null {
  const opts = Object.values(product.sizes.options)
    .filter(
      (a) => a.isVisible || (product as ProductWithDetails).isOneMonthSupply
    )
    .sort((a, b) => b.price - a.price)

  return opts[0] ? opts[0] : null
}

export function getProductsByCategory(products: ProductWithDetails[]) {
  const productsByCategory = products.reduce<ProductsByCategory>(
    (productsByCategory, product) => {
      product.category.forEach((type) => {
        if (!productsByCategory[type]) {
          productsByCategory[type] = []
        }
        productsByCategory[type].push(product)
      })
      return productsByCategory
    },
    {}
  )

  return productsByCategory
}

export function filterProductsByCategory(
  productsByCategory: ProductsByCategory,
  concerns = '',
  priceRange?: PriceRangeOption
): ProductsByCategory {
  const result = {}
  Object.keys(productsByCategory).forEach((category) => {
    result[category] = productsByCategory[category].filter((product) => {
      const price = getOneTimePurchasePrice(product) || 0

      const filterMinPrice = priceRange
        ? price >= Number(priceRange.value.minPrice)
        : true
      const filterByMaxPrice = priceRange
        ? price <= Number(priceRange.value.maxPrice)
        : true
      const filterByConcerns = concerns
        ? product.concerns?.some(
            (concern) =>
              concerns.split(',').includes(concern) ||
              concern === 'skin-concerns'
          )
        : true

      return filterMinPrice && filterByMaxPrice && filterByConcerns
    })
  })

  return result
}

export function sortProductsByCategory(
  productsByCategory: ProductsByCategory,
  byPrice?: string,
  sortOveride?: Record<string, Record<string, number>>
): ProductsByCategory {
  const ProductsOrderByCategoryConfig = {
    ...PRODUCTS_ORDER_BY_CATEGORY,
    ...sortOveride,
  }
  const result = {}
  Object.keys(productsByCategory).forEach((category) => {
    result[category] = sortProducts(
      productsByCategory[category],
      byPrice,
      ProductsOrderByCategoryConfig[category]
    )
  })

  return result
}

export function getMinAndMaxPriceRange(products: ProductWithDetails[]) {
  const productPrices = products.map(
    (product) => getOneTimePurchasePrice(product) || 0
  )

  return {
    minPrice: Math.min(...productPrices),
    maxPrice: Math.max(...productPrices),
  }
}

export function sortProducts(
  products: ProductWithDetails[],
  byPrice?: string,
  productDefaultOrder?: Record<string, number>
): ProductWithDetails[] {
  const sortedProducts = [
    ...products.sort((a, b) => {
      if (productDefaultOrder && !byPrice) {
        const aOrder = productDefaultOrder[a.slug] || Infinity
        const bOrder = productDefaultOrder[b.slug] || Infinity
        return aOrder - bOrder
      }
      const minPrice = getOneTimePurchasePrice(a) || 0
      const maxPrice = getOneTimePurchasePrice(b) || 0
      if (byPrice) {
        return byPrice === 'lowest-price'
          ? minPrice - maxPrice
          : maxPrice - minPrice
      }
      return 0
    }),
  ]

  return sortedProducts
}

export function searchProducts(
  products: ProductWithDetails[],
  searchKeyword = ''
): ProductWithDetails[] {
  const filteredProducts = products.filter((product) => {
    return (
      product.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      product.description.toLowerCase().includes(searchKeyword.toLowerCase())
    )
  })

  return filteredProducts
}

export const getSubscriptionOptionId = (
  product: ProductWithDetails
): number | null => {
  const opts = Object.values(product.sizes.options)
    .filter((a) => a.isVisible || product.isOneMonthSupply)
    .sort((a, b) => a.price - b.price)

  return opts[0] ? opts[0].id : null
}

function applyVat(basePrice: number, countryDetails?: CountryResponse | null) {
  if (countryDetails && countryDetails.isVat) {
    return basePrice * (1 + countryDetails.vatTaxRate)
  }
  return basePrice
}

export function getOneTimePurchasePrice(
  product: Product,
  sizeOptionId?: number,
  countryDetails?: CountryResponse | null
): number | null {
  if (sizeOptionId) {
    return getOneTimePurchasePriceBySize(product, sizeOptionId, countryDetails)
  }
  const opts = Object.values(product.sizes.options)
    .filter(
      (a) => a.isVisible || (product as ProductWithDetails).isOneMonthSupply
    )
    .sort((a, b) => b.price - a.price)

  const sizePrice = opts[0]?.price

  if (typeof sizePrice === 'undefined') return null

  return applyVat(sizePrice, countryDetails)
}

export function getPromoPrice(
  product: ProductWithDetails,
  sizeOptionId?: number,
  countryDetails?: CountryResponse | null
): number {
  if (sizeOptionId) {
    return getPromoPriceBySize(product, sizeOptionId, countryDetails)
  }
  const opts = Object.values(product.sizes.options)
    .filter((a) => a.isVisible || product.isOneMonthSupply)
    .sort((a, b) => b.price - a.price)

  let sizePrice = opts[0]?.price

  if (typeof sizePrice === 'undefined') return 0

  const promo = product.promo

  if (promo) {
    sizePrice = sizePrice * (1 - promo.percentOff / 100)
  }

  return applyVat(sizePrice, countryDetails)
}

export function getProductDiscountPriceAndPercentage(
  product: ProductWithDetails,
  sizeOptionId: number,
  countryDetails?: CountryResponse | null,
  vatEnabled?: boolean
) {
  const otpPrice = product.sizes.options[sizeOptionId].price
  const compareAtPrice = product.sizes.options[sizeOptionId].compareAtPrice

  const otp = vatEnabled ? applyVat(otpPrice, countryDetails) : otpPrice
  const priceBeforeDiscount = vatEnabled
    ? applyVat(compareAtPrice, countryDetails)
    : compareAtPrice

  const isDiscounted = compareAtPrice > 0 && priceBeforeDiscount !== otp

  const percentOffProduct =
    isDiscounted &&
    Math.ceil(((priceBeforeDiscount - otp) / priceBeforeDiscount) * 100)

  return {
    isDiscounted,
    priceBeforeDiscount,
    percentOffProduct,
  }
}

export const getDefaultSizeOptionId = (p: ProductWithDetails): number => {
  let value = getFirstSizeOptionId(p) || 0
  const found = Object.values(p.sizes.options).find((a) => {
    if (p.isOneMonthSupply) {
      return a.text === 'One Month'
    }
    return a.isVisible && slugify(p.sizePack || '') === a.text?.toLowerCase()
  })
  if (found) {
    value = found.id
  }
  return value
}

export const getDefaultSizeOptionText = (p: ProductWithDetails): string => {
  let value = ''
  const found = Object.values(p.sizes.options).find(
    (a) => a.isVisible && slugify(p.sizePack || '') === a.text?.toLowerCase()
  )
  if (found) {
    value = found.text
  }
  return value
}

export function getOneTimePurchasePriceBySize(
  product: Product,
  sizeOptionId: number,
  countryDetails?: CountryResponse | null
): number {
  const sizePrice = product.sizes.options[sizeOptionId].price

  return applyVat(sizePrice, countryDetails)
}

export function getPromoPriceBySize(
  product: Product,
  sizeOptionId: number,
  countryDetails?: CountryResponse | null
): number {
  let sizePrice = product.sizes.options[sizeOptionId].price

  const promo = (product as ProductWithDetails).promo
  if (promo) {
    sizePrice = sizePrice * (1 - promo.percentOff / 100)
  }

  return applyVat(sizePrice, countryDetails)
}

export function getHighestPriceBySizeId(
  product: Product,
  productSizeId: number,
  countryDetails?: CountryResponse | null
): number | null {
  const opts = Object.values(product.sizes.options)
    .filter((a) => a.id === productSizeId)
    .sort((a, b) => b.price - a.price)

  return opts[0] ? applyVat(opts[0].price, countryDetails) : null
}

export function getRetailValue(products: Product[]): number | null {
  let noRetailValue = false
  const retailValue = products.reduce((prevValue, currProd) => {
    const otp = getOneTimePurchasePrice(currProd)
    noRetailValue = otp === null // We can't get OTP for one of the bundled products
    return prevValue + (otp || 0)
  }, 0)

  return noRetailValue ? null : retailValue // Better not to display anything if we can't get the full retail value
}

export function getTwoMonthSubscriptionPrice(size: SizeOption): number {
  return size.subscriptions['2month']?.price || size.price
}

export const mergeProductWithDetails = (
  products: Product[],
  productDetails: ProductDetails[],
  t: TFunction,
  excludeHiddenProducts = true,
  beforeMerge = (p: Product, d: ProductDetails) => [p, d]
): ProductWithDetails[] => {
  const productsWithDetails: ProductWithDetails[] = []
  productDetails.forEach((detail) => {
    if (excludeHiddenProducts && detail.isHidden) {
      return
    }
    const product = products.find((prod) => prod.id === detail.id)
    if (product) {
      const [prod, det] = beforeMerge(product, detail)
      productsWithDetails.push({
        ...(det as ProductDetails),
        ...(prod as Product),
        title: t(`products:${product.slug}.name`, product.title),
      })
    }
  })
  return productsWithDetails
}

export const prepareAccountSystemProducts = (
  products: Product[],
  productDetails: ProductDetails[],
  t: TFunction
): ProductInfo[] => {
  const productsInfo: ProductInfo[] = []
  const productsMap = products.reduce(
    (acc, prod) => ({ ...acc, [prod.id]: prod }),
    {}
  )
  productDetails.forEach((detail) => {
    const product = productsMap[detail.id]
    if (product) {
      productsInfo.push({
        ...product,
        accountSystemAssets: detail.accountSystemAssets,
        title: t(`products:${product.slug}.name`, product.title),
        isDiscontinued: detail.isHidden || detail.isHiddenInAccountSystem,
        hiddenCustomizationOptions: ['Age Bracket'],
      })
    }
  })
  return productsInfo
}

export const getProductPrice = (
  product: Product,
  quantity = 1,
  countryDetails?: CountryResponse | null
) => {
  const size = getCheapestVisibleSize(product)
  const price = size ? getTwoMonthSubscriptionPrice(size) : 0

  return applyVat(price * quantity, countryDetails)
}

export const getSubDiscountRulePrice = (
  product: Product,
  discountKey?: string | null,
  sizeOptionId?: number,
  countryDetails?: CountryResponse | null
) => {
  if (sizeOptionId) {
    const price = getSubscriptionPriceBySize(product, sizeOptionId, discountKey)
    return applyVat(price, countryDetails)
  }

  const size = getCheapestVisibleSize(product)
  const subscriptionOptions = size?.subscriptionOptions['2month']
  const fallBackPrice = size ? getTwoMonthSubscriptionPrice(size) : 0
  const price =
    subscriptionOptions && discountKey
      ? subscriptionOptions[discountKey]?.price || fallBackPrice
      : fallBackPrice

  return applyVat(price, countryDetails)
}

export function getSubscriptionPriceBySize(
  product: Product,
  sizeOptionId: number,
  discountKey?: string | null
): number {
  const sizeOption = product.sizes.options[sizeOptionId]
  const defaultSubPrice = sizeOption.subscriptions['2month'].price

  const price = discountKey
    ? sizeOption.subscriptionOptions['2month'][discountKey]?.price
    : defaultSubPrice

  return price || defaultSubPrice
}

export const getDefaultIntervalCount = (
  product: ProductWithDetails,
  subscriptionInterval = '2month'
): number | undefined => {
  const size = getCheapestVisibleSize(product)
  return size
    ? size.subscriptions[subscriptionInterval]?.interval_count
    : undefined
}

export const fetchProduct = (
  slugOrId: string | number,
  currency: string,
  getState: () => AppState
) => {
  if (typeof slugOrId === 'string') {
    return selectSingleProduct({ slug: slugOrId, currency })(getState())
  }
  return selectSingleProduct({ id: slugOrId, currency })(getState())
}

export const getProductCategoryName = (category: string): string => {
  switch (category) {
    case 'best-sellers': {
      return 'Best Sellers'
    }
    case 'face': {
      return 'Face'
    }
    case 'hair-and-body': {
      return 'Hair & Body'
    }
    case 'sets': {
      return 'Sets'
    }
    case 'accessories': {
      return 'Accessories'
    }
    default: {
      return 'All'
    }
  }
}

export const isSubDiscountKeySame = (
  keys?: string[] | null,
  otherKeys?: string[] | null
) => {
  if (!keys && !otherKeys) {
    return true
  }

  if (!keys || !otherKeys) {
    return false
  }

  return keys?.join(',') === otherKeys.join(',')
}

export const getProductCustomizationAndSizeOptionsWithAvailabilty = (
  product: Product,
  selectedSizeOptionId: number | string,
  selectedOptions: { [key: string]: string | number }
) => {
  const productOOSVariants = getProductOutOfStockVariants(product)

  const customizationOptions: CustomizationOptionsWithAvailabilty = {
    ...product.customizationOptions,
  }

  const sizes: SizeOptionsWithAvailabilty = { ...product.sizes.options }
  let isSelectedVariantOss = false

  const hasNoCustomization =
    Array.isArray(product.customizationOptions) && Array.isArray(product.sizes)

  if (!Object.keys(productOOSVariants).length || hasNoCustomization) {
    return {
      customizationOptions,
      sizes,
      isSelectedVariantOss,
      allVariantsAreOOS: false,
    }
  }

  let allVariantsAreOOS = true

  if (Array.isArray(product.customizationOptions)) {
    Object.values(sizes).forEach((opt) => {
      if (productOOSVariants[opt.id]) {
        opt.outOfStock = true
      } else if (opt.isVisible) {
        allVariantsAreOOS = false
      }
    })
    isSelectedVariantOss = !!productOOSVariants[selectedSizeOptionId]
    return {
      customizationOptions,
      sizes,
      isSelectedVariantOss,
      allVariantsAreOOS,
    }
  }

  const selectedIds = selectedSizeOptionId > 0 ? [selectedSizeOptionId] : []

  if (selectedOptions['Age Bracket']) {
    selectedIds.push(selectedOptions['Age Bracket'])
  }

  const lastCustomLevel = Object.keys(customizationOptions)
    .filter((opt) => opt !== 'Age Bracket')
    .splice(-1)[0]

  if (lastCustomLevel && customizationOptions[lastCustomLevel].options) {
    Object.values(customizationOptions[lastCustomLevel].options).forEach(
      (option) => {
        const variantIds = [...selectedIds, option.id].sort().toString()
        if (selectedOptions[lastCustomLevel] === option.id) {
          isSelectedVariantOss = !!productOOSVariants[variantIds]
        }
        if (productOOSVariants[variantIds]) {
          option.outOfStock = true
        } else if (option.isVisible) {
          allVariantsAreOOS = false
        }
      }
    )
  }

  return {
    customizationOptions,
    sizes,
    isSelectedVariantOss,
    allVariantsAreOOS,
  }
}

export const getProductOutOfStockVariants = (() => {
  const productsOOSVariants: Record<string, Record<number, boolean>> = {}

  return (product: Product) => {
    const prodWithDetails = product as ProductWithDetails

    const cacheKey = prodWithDetails.promo
      ? product.id + prodWithDetails.promo.code
      : product.id

    if (productsOOSVariants[cacheKey]) {
      return productsOOSVariants[cacheKey]
    }

    let outOfStockVariants: Record<number, boolean> = {}

    if (!product.variants) {
      return outOfStockVariants
    }

    outOfStockVariants = product.variants.reduce((acc, variant) => {
      if (variant.outOfStock) {
        acc[[...variant.optionIds].sort().toString()] = true
      }
      return acc
    }, {})

    productsOOSVariants[cacheKey] = outOfStockVariants

    return outOfStockVariants
  }
})()

export const checkIfAllVisibleVariantsOfProductAreOutOfStock = (
  product: Product
) => {
  if (!product.variants || !product.variants.length) {
    return false
  }

  const hasNoCustomization =
    Array.isArray(product.customizationOptions) && Array.isArray(product.sizes)

  if (hasNoCustomization) {
    return false
  }

  let allVariantsAreOOS = true

  const productCustomizationOptions = Object.values(
    product.customizationOptions
  ).reduce<CustomizationOption['options']>((acc, opt) => {
    return { ...acc, ...opt.options }
  }, {})

  for (const variant of product.variants) {
    if (variant.outOfStock) continue
    const hasHiddenOpt = variant.optionIds.some((id) => {
      const isSizeOpt = product.sizes.options?.[id]
      const isCustomOpt = productCustomizationOptions[id]
      if (isSizeOpt) return !isSizeOpt.isVisible
      if (isCustomOpt) return !isCustomOpt.isVisible
      return true
    })

    if (hasHiddenOpt) continue
    allVariantsAreOOS = false
    break
  }

  return allVariantsAreOOS
}

export const checkProductOutOfStock = (product: Product) => {
  return (
    checkIfAllVisibleVariantsOfProductAreOutOfStock(product) ||
    product.outOfStock
  )
}

export const getFirstPromoSizeOrProductDefaultSize = (
  product: ProductWithDetails
) => {
  if (product.promo && product.clearancePromoInfo?.sizes) {
    return +(
      product.clearancePromoInfo.sizes?.find(
        (size) => !!product.sizes.options[size]?.isVisible
      ) || getDefaultSizeOptionId(product)
    )
  }
  return getDefaultSizeOptionId(product)
}

export const isSelectedSizePromoVariant = (
  product: ProductWithDetails,
  size: number | undefined,
  selectedOptions?: SelectedOptions
) => {
  let isPromoSize = !!product.promo

  const clearanceInfo = product.clearancePromoInfo
  if (clearanceInfo?.sizes && size !== undefined) {
    if (!clearanceInfo.sizes?.includes(size)) {
      return false
    }
  }

  if (selectedOptions && Object.keys(selectedOptions).length) {
    for (const option of Object.keys(selectedOptions)) {
      if (clearanceInfo?.[option]) {
        isPromoSize =
          isPromoSize &&
          clearanceInfo?.[option].includes(+selectedOptions[option])
      }
    }
  }

  return isPromoSize
}

export const getDefaultProductSizeOptionIdByStrategy = (
  product: ProductWithDetails,
  sizeStrategy: SizeStrategy
) => {
  switch (sizeStrategy) {
    case SizeStrategy.PROMO_SIZE_DEFAULT: {
      return getFirstPromoSizeOrProductDefaultSize(product)
    }
    case SizeStrategy.HIGHEST_SIZE_DEFAULT: {
      return (
        getHighestVisibleSize(product)?.id || getDefaultSizeOptionId(product)
      )
    }
    case SizeStrategy.DEFAULT:
    default: {
      return getDefaultSizeOptionId(product)
    }
  }
}

export const getProductMonthlySizeInfo = (
  t: TFunction,
  product: ProductWithDetails
) => {
  if (
    !product.category.includes('hair-and-body-v1') &&
    (product.category.includes('clearance-sale') ||
      product.sizePack?.includes('Pack'))
  ) {
    return null
  }

  const isV2HairBodyProduct = product.category.includes('hair-and-body')
  const fourMonthText = t('product:4-month-supply', '4-month Supply')
  const twoMonthText = t('product:2-month-supply', '2-month Supply')
  const oneMonthText = t('product:1-month-supply', '1-month Supply')

  if (isV2HairBodyProduct || product.category.includes('hair-and-body-v1')) {
    const sizeOptions = Object.values(product.sizes.options)
    if (sizeOptions.length <= 1) {
      return twoMonthText
    }
    let lowestSize: null | SizeOption = null
    const optionSizeConfig = {}
    for (const option of sizeOptions) {
      if (!option.isVisible) continue

      if (!lowestSize || lowestSize.price > option.price) {
        if (lowestSize) {
          optionSizeConfig[lowestSize.id] = isV2HairBodyProduct
            ? fourMonthText
            : twoMonthText
        }
        lowestSize = option
        optionSizeConfig[option.id] = isV2HairBodyProduct
          ? twoMonthText
          : oneMonthText
        continue
      }

      optionSizeConfig[option.id] = isV2HairBodyProduct
        ? fourMonthText
        : twoMonthText
    }
    return optionSizeConfig
  }

  return twoMonthText
}
