import * as Sentry from '@sentry/browser'
import { useEffect, useState } from 'react'
import { noop } from '@pangaea-holdings/pangaea-checkout'

export const useCurrentZipCode = (enabled: boolean = true) => {
  const [currentZipCode, setCurrentZipCode] = useState<string | null>(null)

  const setZipCodeFromPosition = async (position) => {
    const { latitude, longitude } = position.coords
    if (window.google) {
      const latLng = new google.maps.LatLng(latitude, longitude)
      const geocoder = new google.maps.Geocoder()
      const data = await geocoder.geocode({
        location: latLng,
      })
      const address_component = data?.results[0]?.address_components?.find(
        (address) => address.types.includes('postal_code')
      )

      const zipCode = address_component?.long_name
      if (zipCode) {
        setCurrentZipCode(zipCode)
      }
    }
  }

  const getCurrentZipCode = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setZipCodeFromPosition, noop)
    } else {
      Sentry.captureMessage("Geolocation is not supported by user's browser")
    }
  }

  useEffect(() => {
    let timer
    if (enabled) {
      timer = setTimeout(() => {
        getCurrentZipCode()
      }, 1000)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [enabled])

  return currentZipCode
}
