import { getWalmartTargetAnnouncementHtml } from 'modules/account/constants'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsNearLocalStore } from './useIsNearLocalStore'

export function useAvailableStoreLocationMessage() {
  const { t } = useTranslation()
  const isNearLocalStore = useIsNearLocalStore()
  const [message, setMessage] = useState<string | null>(null)

  useEffect(() => {
    if (isNearLocalStore) {
      const storeAnnouncementHtml = getWalmartTargetAnnouncementHtml(t)
      setMessage(storeAnnouncementHtml)
    }
  }, [isNearLocalStore])

  return message
}
