import { Box, Flex, Link, Text } from '@chakra-ui/core'
import { getCookie, setCookie } from '@pangaea-holdings/pangaea-checkout'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useRTL } from '../../../i18n/hooks'
import ShopifyNextLink from '../ShopifyNextLink'

const COOKIE_POLICY_NAME = 'eg_cookieconsent_status'

const CookiePolicyBanner = () => {
  const { t } = useTranslation()
  const { isRTL } = useRTL()
  const [showCookiePolicy, setShowCookiePolicy] = useState(false)

  useEffect(() => {
    const cookie = getCookie(COOKIE_POLICY_NAME)
    if (cookie !== 'dismiss') {
      setShowCookiePolicy(true)
    }
  }, [])

  const acceptCookie = () => {
    setCookie(COOKIE_POLICY_NAME, 'dismiss', 365)
    setShowCookiePolicy(false)
  }

  return (
    <Box mb={showCookiePolicy ? ['4rem', '2.8rem'] : []}>
      {showCookiePolicy && (
        <Flex
          direction={['column', 'row']}
          background="nightGreen"
          color="white"
          justify="center"
          alignItems="center"
          position="fixed"
          bottom="0"
          width="100%"
          boxShadow="addressBlockShadow"
          zIndex="sticky"
        >
          <Box flexGrow={1} px="4" py="2">
            <Text mr={isRTL ? 0 : 2} ml={isRTL ? 2 : 0} size="md">
              {t(
                'cookie:accept-message',
                'Lumin uses 3rd-party cookies to ensure you have the best experience on our website.'
              )}
              &nbsp;
              <ShopifyNextLink
                href={'/pages/privacy-policy'}
                textDecoration="underline"
                target="_blank"
                isInternal={false}
                mr={isRTL ? 0 : 2}
                ml={isRTL ? 2 : 0}
              >
                {t('common:learn-more', 'Learn more')}
              </ShopifyNextLink>
            </Text>
          </Box>
          <Flex
            background="seastone2"
            color="moss"
            flexGrow={0}
            align="center"
            minHeight={['auto', '2.8rem']}
            minWidth={['100%', '2.8rem']}
          >
            <Link
              px="4"
              py="2"
              textAlign="center"
              fontWeight="bold"
              onClick={acceptCookie}
              alignSelf="center"
              width="100%"
            >
              {t('common:accept', 'Accept')}
            </Link>
          </Flex>
        </Flex>
      )}
    </Box>
  )
}

export default CookiePolicyBanner
